<template lang="pug">
section.section(v-if="props.tabs")
  .tabs.is-centered.is-boxed
    ul
      //- li(v-for="(item, index) in tabs" v-on:click="activate(index)" v-bind:class="{ 'is-active': route.path === item.link }")
      li(v-for="(item, index) in tabType" v-on:click="activate(index)" v-bind:class="{ 'is-active': route.path === item.link }")
        nuxt-link(:to="item.link")
          o-icon(v-if="item.icon" :icon="item.icon")
          | {{ item.label }}
</template>

<script setup>
const route = useRoute();
const props = defineProps({ tabs: { type: String, required: true } });
function activate(index) {
  console.log(index);
}

const tabType = computed(() => {
  if (props.tabs === "account") {
    return [
      {
        label: "Account & Team",
        link: "/account/",
        icon: "home-account",
      },
      {
        label: "Manage Plan & Billing",
        link: "/account/plan/",
        icon: "credit-card",
      },
      // {
      //   label: "Account Settings",
      //   link: "/account/settings/",
      // },
    ];
  } else if (props.tabs === "brand") {
    return [
      {
        label: "Colors",
        link: "/brand/colors",
        icon: "format-color-fill",
      },
      {
        label: "Fonts",
        link: "/brand/fonts",
        icon: "format-size",
      },
      // {
      //   label: "Designer",
      //   link: "/brand/designer",
      //   icon: "progress-pencil",
      // },
      {
        label: "Icons",
        link: "/brand/icons",
        icon: "tag-faces",
      },
    ];
  } else if (props.tabs === "email") {
    return [
      {
        label: "Overview",
        link: "/email/",
        icon: "email-open-outline",
      },
      {
        label: "Campaigns",
        link: "/email/campaigns/",
        icon: "invoice-text-send-outline",
      },
      {
        label: "Email Lists",
        link: "/email/lists/",
        icon: "format-list-text",
      },
      // {
      //   label: 'Mail Flows',
      //   link: '/email/flows/'
      // },
      {
        label: "Settings",
        link: "/email/settings/",
        icon: "cog-outline",
      },
    ];
  } else if (props.tabs === "media") {
    return [
      {
        label: "Library",
        link: "/media/",
      },
      // {
      //   label: 'Library',
      //   link: '/media/library',
      // },
      // {
      //   label: 'Builder',
      //   link: '/media/builder',
      // },
    ];
  } else if (props.tabs === "people") {
    return [
      {
        label: "People",
        link: `/people/`,
      },
      {
        label: "Contacts",
        link: `/people/contacts/`,
      },
      {
        label: "Members",
        link: `/people/members/`,
      },
      {
        label: "Customers",
        link: `/people/customers/`,
      },
    ];
  } else if (props.tabs === "sell") {
    return [
      // {
      //   label: 'Setup',
      //   link: '/sell/',
      // },
      // {
      //   label: "Preowned Pro",
      //   link: "/sell/preowned",
      //   icon: "hand-extended-outline",
      // },
      {
        label: "Transactions",
        link: "/sell/transactions",
        icon: "autorenew",
      },
      {
        label: "Customers",
        link: "/sell/customers",
        icon: "face-man",
      },
      {
        label: "Products",
        link: "/sell/products",
        icon: "tag-heart-outline",
      },
      {
        label: "Payment",
        link: "/sell/payment",
        icon: "hand-extended-outline",
      },
    ];
  } else if (props.tabs === "team") {
    return [
      {
        label: "Welcome",
        link: "/team/",
      },
      {
        label: "Current Team",
        link: "/team/current",
      },
      {
        label: "All Teams",
        link: "/team/all/",
      },
      // {
      //   label: 'Settings',
      //   link: '/team/settings/',
      // },
    ];
  } else if (props.tabs === "website") {
    return [
      {
        label: "Content",
        link: `/websites/${route.params.website}/content`,
        icon: "newspaper-variant",
      },
      {
        label: "Layouts",
        link: `/websites/${route.params.website}/layouts`,
        icon: "page-layout-body",
      },
      // {
      //   label: "Components",
      //   link: `/websites/${route.params.website}/components`,
      //   icon: "bookshelf",
      // },
      // {
      //   label: 'Extensions',
      //   link: `/websites/${this.$route.params.website}/extensions`,
      // },
      {
        label: "Redirects",
        link: `/websites/${route.params.website}/redirects`,
        icon: "arrow-decision",
      },
      {
        label: "Settings",
        link: `/websites/${route.params.website}/settings`,
        icon: "cog",
      },
    ];
  } else if (props.tabs === "admin") {
    return [
      {
        label: "Zues",
        link: `/admin/`,
        icon: "lightning-bolt",
      },
      {
        label: "Themes",
        link: `/admin/themes`,
        icon: "shape",
      },
      {
        label: "Backups",
        link: `/admin/backup`,
        icon: "backup-restore",
      },
    ];
  }
});
// export default {
//   props: {
//     tabs: {
//       type: Array,
//       default: null,
//     },
//   },
//   methods: {
//     activate(index) {
//       this.activeItem = index;
//     },
//   },
// };
</script>
